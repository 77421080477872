import Vue from "vue";
import * as Api from "../../axios-generated/backoffice/";

// @ts-ignore
import axios from "@axios";

const ADS_API = new Api.AdsApi(
  undefined,
  Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL,
  axios
);

const FLOWS_API = new Api.FlowsApi(
  undefined,
  Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL,
  axios
);

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAds(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        ADS_API.findAds(
          queryParams.idQuery,
          queryParams.zipQuery,
          queryParams.sortBy,
          queryParams.perPage,
          queryParams.page,
          queryParams.sortDesc,
          queryParams.minIntegrationDate,
          queryParams.maxIntegrationDate,
          queryParams.enabled,
          queryParams.flow,
          queryParams.type_parent,
          queryParams.service
        )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAdsPrograms(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        ADS_API.findAdsPrograms(
          queryParams.nameQuery,
          queryParams.idQuery,
          queryParams.zipQuery,
          queryParams.sortBy,
          queryParams.perPage,
          queryParams.page,
          queryParams.sortDesc,
          queryParams.minDeliveryDate,
          queryParams.maxDeliveryDate,
          queryParams.minIntegrationDate,
          queryParams.maxIntegrationDate,
          queryParams.enabled,
          queryParams.flow
        )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAdsProgramsLots(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        ADS_API.findAdsProgramsLots(queryParams.id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchFlowsOrigins(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        FLOWS_API.findFlowsOrigins(queryParams.typeOffers, queryParams.status)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
